<template>
  <div class="container global-list-main-container">
    <div class="upper-global-title">
      <span class=""> Vehículos </span>
    </div>
    <div class="upper-global-hint">
      <b-icon icon="lightbulb-outline" type="is-primary"> </b-icon>
      <span class="global-form-hint-color"> Recuerda: </span> {{ hint }}
    </div>
    <b-tabs type="is-boxed">
      <b-tab-item label="Vehículos" icon="truck-outline">
        <VehiculesList></VehiculesList>
      </b-tab-item>
      <b-tab-item label="Tipos de embalaje" icon="package-variant-closed">
        <PackagingList></PackagingList>
      </b-tab-item>
      <b-tab-item label="Tipo de permisos" icon="dump-truck">
        <PermissionList></PermissionList>
      </b-tab-item>
      <b-tab-item label="Configuración autotransporte" icon="truck">
        <TransportConfigurationsList></TransportConfigurationsList>
      </b-tab-item>
      <b-tab-item label="Tipos de remolque" icon="truck-trailer">
        <TrailerTypesList></TrailerTypesList>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import VehiculesList from "../components/VehiculesList";
import PackagingList from "../components/PackagingList.vue";
import PermissionList from "../components/PermissionList.vue";
import TransportConfigurationsList from "../components/TransportConfigurationsList";
import TrailerTypesList from "../components/TrailerTypesList";
// @ is an alias to /src
export default {
  name: "VehiculesComponents",
  components: {
    VehiculesList,
    PackagingList,
    PermissionList,
    TransportConfigurationsList,
    TrailerTypesList,
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch("CHANGEACTIVE", "vehicules");
  },
  async created() {
    let allPromises = [];
    if (this.$store.getters.PACKAGINGS.length === 0) {
      allPromises.push(this.$store.dispatch("GETPACKAGINGS"));
    }
    if (this.$store.getters.TRANSPORTATIONPERMISSIONS.length === 0) {
      allPromises.push(this.$store.dispatch("GETTRASNPORTATIONPERMISSIONS"));
    }
    allPromises.push(this.$store.dispatch("GETVEHICULES"));
    if (this.$store.getters.TRANSPORTATIONCONFIGURATION.length === 0) {
      allPromises.push(this.$store.dispatch("GETTRANSPORTATIONCONFIGURATION"));
    }
    if (this.$store.getters.TRAILERTYPES.length === 0) {
      allPromises.push(this.$store.dispatch("GETTRAILERTYPE"));
    }
    if (this.$store.getters.WEIGHTUNITS.length === 0) {
      allPromises.push(this.$store.dispatch("GETWEIGHTUNITS"));
    }
    if (this.$store.getters.DANGEROUSMATERIALS.length === 0) {
      allPromises.push(this.$store.dispatch("GETDANGEROUSMATERIALS"));
    }
    await Promise.all(allPromises);
  },
  methods: {},
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
