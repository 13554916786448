<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title" v-if="!vehiculeInformation">
      Agregar Vehículo
    </h1>

    <div v-if="!vehiculeInformation" class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado </span>
    </div>

    <h3 class="form-division">Información general</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Clave del vehículo (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': vehiculeRequiredErrors.CVE_VEHI }"
        :message="{
          'La clave del vehículo no es valida': vehiculeRequiredErrors.CVE_VEHI,
        }"
      >
        <b-input
          :disabled="vehiculeInformation"
          placeholder="Ejemplo: 123"
          expanded
          icon="car"
          v-model="newVehicule.CVE_VEHI"
          required
        ></b-input>
      </b-field>
      <b-field
        label="Placas (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': vehiculeRequiredErrors.PLACAS }"
        :message="{
          'La placas no son validas': vehiculeRequiredErrors.PLACAS,
        }"
      >
        <b-input
          placeholder="Ejemplo: 888-AAA"
          expanded
          icon="car-settings"
          v-model="newVehicule.PLACAS"
          required
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Marca (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': vehiculeRequiredErrors.MARCA_VEH }"
        :message="{
          'La marca del vehículo no es valida':
            vehiculeRequiredErrors.MARCA_VEH,
        }"
      >
        <b-input
          placeholder="Ejemplo: Nissan"
          expanded
          icon="dump-truck"
          v-model="newVehicule.MARCA_VEH"
          required
        ></b-input>
      </b-field>
      <b-field
        label="Modelo (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': vehiculeRequiredErrors.MODELO }"
        :message="{
          'El modelo del vehículo no es valido': vehiculeRequiredErrors.MODELO,
        }"
      >
        <b-input
          placeholder="Ejemplo: 2012"
          expanded
          icon="rv-truck"
          v-model="newVehicule.MODELO"
          required
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Tipo vehiculo (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': vehiculeRequiredErrors.TIPO_VEH }"
        :message="{
          'El tipo del vehículo no es valida': vehiculeRequiredErrors.TIPO_VEH,
        }"
      >
        <b-input
          placeholder="Ejemplo: Estaquitas"
          expanded
          icon="car-sports"
          v-model="newVehicule.TIPO_VEH"
          required
        ></b-input>
      </b-field>
      <b-field
        label="Tipo de combustible (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': vehiculeRequiredErrors.TIPO_GAS }"
        :message="{
          'El tipo de combustible del vehículo no es valido':
            vehiculeRequiredErrors.TIPO_GAS,
        }"
      >
        <b-input
          placeholder="Ejemplo: Gasolina"
          expanded
          icon="tanker-truck"
          v-model="newVehicule.TIPO_GAS"
          required
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field label="Aseguradora (Opcional)" expanded label-position="inside">
        <b-input
          placeholder="Ejemplo: GNP Seguros"
          expanded
          icon="car-door-lock"
          v-model="newVehicule.SEGURO_CIVIL"
        />
      </b-field>
      <b-field
        label="Poliza de seguro (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 123456789"
          expanded
          icon="card-bulleted-outline"
          v-model="newVehicule.POLIZA_CIVIL"
        />
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Aseguradora de daños a medio ambiente (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: GNP Seguros"
          expanded
          icon="car-door-lock"
          v-model="newVehicule.SEGURO_AMBIENTE"
        />
      </b-field>
      <b-field
        label="Poliza de daños a medio ambiente (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 123456789"
          expanded
          icon="card-bulleted-outline"
          v-model="newVehicule.POLIZA_AMBIENTE"
        />
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Peso bruto del vehiculo en kilogramos (Requerido)"
        expanded
        label-position="inside"
      >
        <UnitInput
          placeholder="Ejemplo: 250"
          expanded
          icon="truck"
          v-model="newVehicule.BRUTO"
          type="number"
          step="any"
          unit="kg"
          required=""
        />
      </b-field>
    </b-field>

    <div class="add-global-controls" v-if="vehiculeInformation">
      <b-button type="is-success" @click="saveVehicule(1)"
        >Actualizar vehículo</b-button
      >
      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>

    <div class="add-global-controls" v-if="!vehiculeInformation">
      <b-button type="is-success" @click="saveVehicule(0)"
        >Guardar vehículo</b-button
      >

      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
import { validateFields, checkValidationErrors } from "../../../utils/fns";
// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

import Cleave from "cleave.js";
import { fillObjectForm } from "../../../utils/component-fns";
// @ is an alias to /src
export default {
  name: "AddBrandModal",
  props: ["vehiculeInformation"],
  components: {},
  directives: { cleave },
  data() {
    return {
      newVehicule: {
        CVE_VEHI: "",
        PLACAS: "",
        PESO_MAX: 0,
        BRUTO: 0,
        VOL_MAX: 0,
        MODELO: "",
        MARCA_VEH: "",
        TIPO_VEH: "",
        TIPO_GAS: "",
        SEGURO_CIVIL: "",
        POLIZA_CIVIL: "",
        SEGURO_AMBIENTE: "",
        POLIZA_AMBIENTE: "",
        STATUS: "AC",
      },
      vehiculeRequiredErrors: {
        CVE_VEHI: false,
        PLACAS: false,
        MODELO: false,
        MARCA_VEH: false,
        TIPO_VEH: false,
        TIPO_GAS: false,
      },
    };
  },
  mounted() {
    // Fill the brand information in form fields
    if (this.vehiculeInformation) {
      // Fill the form with received information
      fillObjectForm(this.vehiculeInformation, this.newVehicule);
      this.newVehicule._id = this.vehiculeInformation._id;
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    /**
     * @desc saves or updates a new brand depending on the type
     */
    async saveVehicule(type) {
      try {
        this.vehiculeRequiredErrors = validateFields(
          this.newVehicule,
          this.vehiculeRequiredErrors
        );
        if (checkValidationErrors(this.vehiculeRequiredErrors)) {
          let response = await this.$store.dispatch(
            type === 0 ? "SAVEVEHICULE" : "EDITVEHICULE",
            this.newVehicule
          );
          if (response == "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se guardó exitosamente el vehículo",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                if (type === 0) this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message:
                "Hubo un error al guardar el vehículo en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar el vehículo la base de datos revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al guardar el vehículo en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    deleteInformation() {
      this.newVehicule = {
        CVE_VEHI: "",
        PLACAS: "",
        PESO_MAX: 0,
        VOL_MAX: 0,
        MODELO: "",
        MARCA_VEH: "",
        TIPO_VEH: "",
        TIPO_GAS: "",
        SEGURO_CIVIL: "",
        POLIZA_CIVIL: "",
        STATUS: "AC",
      };
    },
  },
  computed: {},
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
